import { DocumentInfoResponseModel } from '@npaShared/models/document/document-info.response.model';
import { DocumentRevisionModel } from '@npaShared/models/document/document-revision.model';
import { HttpStatusCode } from '@angular/common/http';
import { SigningVersionModel } from '@npaShared/models/document/documents-revision-info.model';
import { filteringBlankDocuments } from '@npaShared/functions/filtering-documents';
import { RoutePointWithAdditionalInfo } from '@npaShared/models/route/route.models';
import { getActualDocumentVersion } from './document-version.helper';

/**
 * Получение модели ревизий документа
 *
 * @param document документ
 * @returns модель ревизий документа
 */
export const getDocumentRevision = (
  document: DocumentInfoResponseModel,
  activePoint: RoutePointWithAdditionalInfo,
  signableVersions: SigningVersionModel[] = [],
): DocumentRevisionModel | null => {
  if (!document || !activePoint) {
    return null;
  }

  if (signableVersions?.length) {
    const signableVersion = signableVersions.find((d) => d.ldeDocumentId === document.ldeDocumentId);
    if (signableVersion) {
      const signingRevision = getSigningRevision(signableVersion);

      return {
        documentId: signableVersion.documentId,
        ldeId: signableVersion.ldeDocumentId,
        revision: signingRevision,
      };
    }
  }

  const actualVersion = getActualDocumentVersion(document, activePoint.phaseId, signableVersions);
  if (!actualVersion) {
    return null;
  }

  return {
    documentId: document.id,
    ldeId: document.ldeDocumentId,
    revision: actualVersion.revision,
  };
};

export const getDocumentRevisionsForSignature = (
  documents: DocumentInfoResponseModel[],
  signingVersions: SigningVersionModel[],
  point: RoutePointWithAdditionalInfo,
): DocumentRevisionModel[] => {
  const documentsForDecisionAction = [...filteringBlankDocuments(documents, point.phaseId)];
  let revisionForSign = documentsForDecisionAction.map((d) => getDocumentRevision(d, point));

  if (signingVersions.length) {
    revisionForSign = revisionForSign.map((r) => {
      const signingVersion = signingVersions.find((v) => r.documentId === v.documentId);
      if (signingVersion) {
        return { ...r, revision: getSigningRevision(signingVersion) };
      }
      return r;
    });
  }

  return revisionForSign;
};

/**
 * Получить ревизию переданного документа для подписания
 *
 * @param signingVersion версия документа
 * @param isFromSocket версия получена из websocket
 * @returns
 */
export const getSigningRevision = (signingVersion: SigningVersionModel, isFromSocket = false): string => {
  if (!signingVersion) {
    return '';
  }

  const revisions: { [key: number]: string } = {
    [HttpStatusCode.Ok]: signingVersion.ldeRevisionId,
    [HttpStatusCode.NoContent]: signingVersion.parentLdeRevisionId,
  };

  if (isFromSocket) {
    return revisions[signingVersion.ldeResponseCode] || '';
  }

  return revisions[signingVersion.ldeResponseCode] || signingVersion.parentLdeRevisionId || '';
};
