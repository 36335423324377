import { Injectable } from '@angular/core';

import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import {
  DocumentInfoResponseModel,
  DocumentVersionInfoModel,
} from '@npaShared/models/document/document-info.response.model';
import { SigningVersionModel } from '@npaShared/models/document/documents-revision-info.model';
import { getSigningRevision } from '@npaShared/helpers/document-revision';
import { DocumentTypesEnum } from '@npaShared/enums/document-types.enum';
import { getActualDocumentVersion } from '@npaShared/helpers/document-version.helper';
import {
  needToCreateLuzVersionsForSigning,
  needToCreateVersionsForSigning,
} from '@npaShared/functions/documents-for-signature';
import { Route } from '@npaShared/models/route/route.models';
import {
  ChangeRevisionFromSocket,
  ResetDocumentsState,
  SetActiveDocument,
  SetDocuments,
  SetSigningVersions,
  SetRemarksSheetSigningVersions,
  UpdateActiveVersion,
  SetActualPhaseIdForActivePoint,
} from './documents.action';

export interface DocumentsStoreModel {
  documents: DocumentInfoResponseModel[];
  /** Версии документов для подписи */
  documentSigningVersions: SigningVersionModel[];
  /** Версии документов ЛУЗа для подписания */
  remarksSheetSigningVersions: SigningVersionModel[];
  /** Активный документ */
  activeDocument: DocumentInfoResponseModel | null;
  actualVersion: DocumentVersionInfoModel | null;
  /** Признак наличия альтернативы в хотя бы одной ревизии */
  isHasRemarksAlternatives: boolean;
  /** id этапа активной точки */
  actualPhaseIdForActivePoint: number;
}

const initialState: DocumentsStoreModel = {
  documents: [],
  documentSigningVersions: [],
  remarksSheetSigningVersions: [],
  activeDocument: null,
  actualVersion: null,
  isHasRemarksAlternatives: false,
  actualPhaseIdForActivePoint: 0,
};

@Injectable()
@State<DocumentsStoreModel>({
  name: 'documents',
  defaults: initialState,
})
export class DocumentsState {
  private versionsFromSocket: SigningVersionModel[] = [];

  @Selector()
  public static documents(state: DocumentsStoreModel): DocumentInfoResponseModel[] {
    return state.documents;
  }

  @Selector()
  public static activeDocument(state: DocumentsStoreModel): DocumentInfoResponseModel {
    return state.activeDocument;
  }

  @Selector()
  public static actualVersionByActiveDocument(state: DocumentsStoreModel): DocumentVersionInfoModel {
    return state.actualVersion;
  }

  public static actualVersionByDocument(
    document: DocumentInfoResponseModel,
  ): (state: DocumentsStoreModel) => DocumentVersionInfoModel {
    return createSelector([DocumentsState], (state: DocumentsStoreModel): DocumentVersionInfoModel => {
      const newActualVersion = getUpdatingActualVersion(state, document);
      return newActualVersion;
    });
  }

  /**
   * @returns документы для подписания, если надо использовать версии для подписания
   * @returns пустой массив, если не надо использовать версии для подписания
   */
  public static allSigningVersionsIfNeedToUseVersionForSigning(
    route: Route,
    pointId: number,
  ): (state: DocumentsStoreModel) => SigningVersionModel[] {
    return createSelector([DocumentsState], (state: DocumentsStoreModel): SigningVersionModel[] => {
      const needToUseVersionForSigning = needToCreateVersionsForSigning(route, pointId);
      const needToUseLuzVersionForSigning = needToCreateLuzVersionsForSigning(route, pointId);

      if (needToUseVersionForSigning || needToUseLuzVersionForSigning) {
        return [...state.documentSigningVersions, ...state.remarksSheetSigningVersions];
      }

      return [];
    });
  }

  @Selector()
  public static allSigningVersions(state: DocumentsStoreModel): SigningVersionModel[] {
    return [...state.documentSigningVersions, ...state.remarksSheetSigningVersions];
  }

  @Selector()
  public static signingVersions(state: DocumentsStoreModel): SigningVersionModel[] {
    return state.documentSigningVersions;
  }

  @Selector()
  public static signingVersionsRemarksSheet(state: DocumentsStoreModel): SigningVersionModel[] {
    return state.remarksSheetSigningVersions;
  }

  @Action(SetDocuments)
  public setDocuments({ patchState }: StateContext<DocumentsStoreModel>, { documents }: SetDocuments): void {
    patchState({ documents });
  }

  @Action(SetActualPhaseIdForActivePoint)
  public setActualPhaseIdForActivePoint(
    { patchState }: StateContext<DocumentsStoreModel>,
    { phaseId }: SetActualPhaseIdForActivePoint,
  ): void {
    patchState({ actualPhaseIdForActivePoint: phaseId });
  }

  @Action(SetActiveDocument)
  public setActiveDocument(
    { getState, patchState }: StateContext<DocumentsStoreModel>,
    { document }: SetActiveDocument,
  ): void {
    const newActualVersion = getUpdatingActualVersion(getState(), document);
    patchState({ activeDocument: document, actualVersion: newActualVersion });
  }

  @Action(UpdateActiveVersion)
  public updateActiveVersion({ patchState, getState }: StateContext<DocumentsStoreModel>): void {
    const state = getState();
    const newActualVersion = getUpdatingActualVersion(state, state.activeDocument);
    patchState({ actualVersion: newActualVersion });
  }

  @Action(SetSigningVersions)
  public setSigningVersion(
    { getState, patchState }: StateContext<DocumentsStoreModel>,
    { documentSigningVersions }: SetSigningVersions,
  ): void {
    const updatingData = this.updateSigningVersions(getState(), documentSigningVersions);

    patchState({
      documentSigningVersions: updatingData.updatingVersions,
      actualVersion: updatingData.newActualVersion,
    });
  }

  @Action(SetRemarksSheetSigningVersions)
  public setRemarksSheetSigningVersions(
    { getState, patchState }: StateContext<DocumentsStoreModel>,
    { remarksSheetSigningVersions }: SetRemarksSheetSigningVersions,
  ): void {
    const updatingData = this.updateSigningVersions(getState(), remarksSheetSigningVersions);

    patchState({
      remarksSheetSigningVersions: updatingData.updatingVersions,
      actualVersion: updatingData.newActualVersion,
    });
  }

  @Action(ChangeRevisionFromSocket)
  public changeRevisionFromSocket(
    { patchState, getState }: StateContext<DocumentsStoreModel>,
    { signingVersion }: ChangeRevisionFromSocket,
  ): void {
    const isFromSocket = true;
    const state = getState();
    this.versionsFromSocket.push(signingVersion);

    const updatingDocumentSigningVersions = this.getUpdatingVersions(state.documentSigningVersions, isFromSocket);
    const updatingRemarksSheetSigningVersions = this.getUpdatingVersions(
      state.remarksSheetSigningVersions,
      isFromSocket,
    );
    const allDocumentSigningVersions = [...updatingDocumentSigningVersions, ...updatingRemarksSheetSigningVersions];

    if (signingVersion.ldeDocumentId === state.activeDocument.ldeDocumentId) {
      const newActualVersion = getUpdatingActualVersion(
        state,
        state.activeDocument,
        allDocumentSigningVersions,
        isFromSocket,
      );
      patchState({ actualVersion: newActualVersion });
    }

    patchState({
      documentSigningVersions: updatingDocumentSigningVersions,
      remarksSheetSigningVersions: updatingRemarksSheetSigningVersions,
    });
  }

  @Action(ResetDocumentsState)
  public resetDocumentsState({ setState }: StateContext<DocumentsStoreModel>): void {
    this.versionsFromSocket = [];
    setState(initialState);
  }

  private getUpdatingVersions(versions: SigningVersionModel[], isFromSocket = false): SigningVersionModel[] {
    return versions.map((version) => {
      const versionFromSocket = this.versionsFromSocket.find((v) => v.ldeDocumentId === version.ldeDocumentId);
      if (versionFromSocket) {
        const signingRevision = getSigningRevision(versionFromSocket, isFromSocket);
        const updatingVersion: SigningVersionModel = { ...version, ldeRevisionId: signingRevision };
        return updatingVersion;
      }

      return version;
    });
  }

  private updateSigningVersions(
    state: DocumentsStoreModel,
    signingVersions: SigningVersionModel[],
  ): { updatingVersions: SigningVersionModel[]; newActualVersion: DocumentVersionInfoModel } {
    const updatingVersions = this.getUpdatingVersions(signingVersions);
    const newActualVersion = getUpdatingActualVersion(state, state.activeDocument, updatingVersions);

    return { updatingVersions, newActualVersion };
  }
}

const getUpdatingActualVersion = (
  state: DocumentsStoreModel,
  document: DocumentInfoResponseModel,
  documentSigningVersions: SigningVersionModel[] = [],
  isFromSocket = false,
): DocumentVersionInfoModel => {
  const allDocumentSigningVersions = [...state.documentSigningVersions, ...state.remarksSheetSigningVersions];
  const signingVersions = documentSigningVersions.length ? documentSigningVersions : allDocumentSigningVersions;
  const actualVersion = getActualDocumentVersion(document, state?.actualPhaseIdForActivePoint, signingVersions);

  if (document?.documentType.id === DocumentTypesEnum.REMARKS_SHEET) {
    const revision = state.remarksSheetSigningVersions.find((v) => v.ldeDocumentId === document.ldeDocumentId);
    if (revision) {
      const signingRevision = getSigningRevision(revision, isFromSocket);

      return { ...actualVersion, revision: signingRevision };
    }
  }

  return actualVersion;
};
