import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, first, switchMap, tap } from 'rxjs/operators';

import { Store } from '@ngxs/store';
import { AuthApiService } from '@npaApi/auth.api.service';
import { AuthUserIsproStoreService } from '@npaCore/store/auth-user-ispro-store.service';
import { CurrentUserIsproStoreService } from '@npaCore/store/current-user-ispro-store.service';
import { MainScreenInteropService } from '@rest/main-screen-interop.service';
import { CredentialStorageService } from '@shared/services/credential-storage.service';
import { DeviceInfoService } from '@shared/services/device-info.service';
import { UrlsService } from '@shared/services/urls.service';
import { StatisticEmployeeFromState } from '@store/main-screen/statistic-employee-from/statistic-employee-from.state';
import { HideBackdrop, ShowBackdrop } from '@store/visibility-backdrop/visibility-backdrop.action';
import { NavController } from '@ionic/angular';
import { routeNames } from 'src/app/app-routing/route-names.const';
import { AuthenticationInformationState } from '@store/authentication-information/authentication-information-store.state';
import { CurrentUserAissdStoreService } from '@npaCore/store/current-user-aissd-store.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  /** Показывает то, обновляется ли сейча JWT-токен пользователя НПА по его сохраненным логину/паролю */
  private isRefreshingNpaToken$ = new BehaviorSubject<boolean>(false);

  /** Показывает, обновляется ли сейчас JWT-токен пользователя ООГ по его сохраненному логин/паролю */
  private isRefreshOogToken$ = new BehaviorSubject<boolean>(false);
  constructor(
    private credentialsStorage: CredentialStorageService,
    private authService: AuthApiService,
    private deviceInfoService: DeviceInfoService,
    private urlsService: UrlsService,
    private store: Store,
    private mainScreenInterop: MainScreenInteropService,
    private authUserIspro: AuthUserIsproStoreService,
    private currentUserIspro: CurrentUserIsproStoreService,
    private navController: NavController,
    private npaUserStore: CurrentUserAissdStoreService,
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      request.url.startsWith(environment.baseUrl) ||
      request.url.startsWith(this.urlsService.getHost + '/' + 'npa/api')
    ) {
      return this.npaInterceptor(request, next);
    }

    if (
      request.url.startsWith(environment.oogUrl) ||
      request.url.startsWith(this.urlsService.getHost + '/' + 'oog-mobile')
    ) {
      return this.oogInterceptor(request, next);
    }

    return next.handle(request);
  }

  /** Интерцептор запросов к ООГ-микросервису */
  private oogInterceptor(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isRefreshOogToken$.getValue()) {
      this.isRefreshOogToken$.pipe(
        filter((refreshing) => refreshing === false),
        first(),
        switchMap(() => next.handle(request)),
      );
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.Unauthorized && this.credentialsStorage.hasOogCredentials()) {
          return this.handleOog401Error(request, next);
        }
        return throwError(error);
      }),
    );
  }

  /** Интерцептор запросов к микросервису НПА */
  private npaInterceptor(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isRefreshingNpaToken$.getValue()) {
      this.isRefreshingNpaToken$.pipe(
        filter((refreshing) => refreshing === false),
        first(),
        switchMap(() => next.handle(request)),
      );
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.Unauthorized) {
          return this.handleNpa401Error(request, next);
        }

        return throwError(error);
      }),
    );
  }

  private handleNpa401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.store.dispatch(ShowBackdrop);
    this.isRefreshingNpaToken$.next(true);

    const showAuthFormDuringModeration = this.store.selectSnapshot(
      AuthenticationInformationState.showAuthFormDuringModeration,
    );

    if (showAuthFormDuringModeration) {
      return this.reLoginFromMO(request, next);
    }

    this.redirectToLogin();
    return next.handle(request);
  }

  private reLoginFromMO(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const npaCredentials = this.credentialsStorage.getNpaCredentials();

    if (!npaCredentials) {
      this.redirectToLogin();
      throw new Error('Отсутствуют данные пользователя');
    }

    return this.authService.loginAisSd(npaCredentials).pipe(
      finalize(() => {
        this.isRefreshingNpaToken$.next(false);
        this.store.dispatch(HideBackdrop);
      }),
      switchMap(() => next.handle(request)),
      catchError((error: HttpErrorResponse) => throwError(error)),
    );
  }

  private redirectToLogin(): void {
    this.npaUserStore.resetCurrentUser();
    this.navController.navigateRoot([routeNames.auth, routeNames.login]).then(() => {
      this.isRefreshingNpaToken$.next(false);
      this.store.dispatch(HideBackdrop);
    });
  }

  private handleOog401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const oogCredentials = this.credentialsStorage.getOogCredentials();
    if (!oogCredentials) {
      throw new Error('Отсутствуют данные пользователя OOG-системы');
    }
    this.isRefreshOogToken$.next(true);
    this.store.dispatch(ShowBackdrop);

    return this.authService.loginIsPro(oogCredentials).pipe(
      finalize(() => {
        this.isRefreshOogToken$.next(false);
        this.store.dispatch(HideBackdrop);

        const currentId = this.currentUserIspro.getCurrentUserSnapshot()?.id;
        if (currentId !== this.authUserIspro.getCurrentUserSnapshot()?.id) {
          const statistics = this.store.selectSnapshot(StatisticEmployeeFromState.getStatistics);
          const transferredRight = statistics?.find((s) => s.employeeFrom.id === currentId);
          this.mainScreenInterop.saveRightsToSession(transferredRight?.transferredRightId).pipe(first()).subscribe();
        }
      }),
      tap(() => this.deviceInfoService.saveDeviceInfo()),
      switchMap(() => next.handle(request)),
      catchError((error: HttpErrorResponse) => throwError(error)),
    );
  }
}

export const HttpErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpErrorInterceptor,
  multi: true,
};
